import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '鱿动力-员工激励平台|员工激励方案|企业内联网',
      desc: '鱿动力为您打造数字化员工激励体验，制定员工激励方案，营造积极的企业文化，提高员工敬业度、工作效率和参与度。'
    },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/price',
    name: 'price',
    meta: {
      title: '鱿动力产品价格-员工激励平台|员工激励方案|企业内联网',
      desc: '鱿动力为您打造数字化员工激励体验，制定员工激励方案，营造积极的企业文化，提高员工敬业度、工作效率和参与度。30人团队首年免费。'
    },
    component: () => import('@/views/Price.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/registerInfo',
    name: 'registerInfo',
    component: () => import('@/views/RegisterInfo.vue')
  },
  {
    path: '/findPass',
    name: 'findPass',
    component: () => import('@/views/FindPass.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  }
})

export default router
